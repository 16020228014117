import React from "react";
import PropTypes from "prop-types";
import RBXLogo  from "../../../assets/rbx-company/RBX-Logo-white.svg";
import MergedWords from "@js/microsites/rbx-company/general-components/merged-words";
import SimpleNavigation from "@js/header/simple-navigation";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import LanguageSwitch from "@js/i18n/language-switch";

const RBXHeader = (props) => {
  const location = useLocation();
  const mainMenuOpen = useSelector((reduxStore) => reduxStore.appStore.mainMenuOpen);

  return (
    <>
      <header id="site-header" data-menu-open={mainMenuOpen}>
        <div className="container-fluid">
          <div className="row space-between">
            <div className="col-8 logo">
              <div className={"fixed-wrapper"}>
                <Link to={"/"}>
                  <img
                    src={RBXLogo}
                    className="rbx-logo"
                    alt={"Logo RBX"}
                  />
                </Link>
              </div>
            </div>
            <div className="col-16 col-lg-8 menu">
              <div className={"fixed-wrapper"}>
                <SimpleNavigation/>
              </div>
            </div>
          </div>
        </div>
      </header>
      {location.pathname === "/" &&
        <div className="header-words align-items-start justify-content-center d-flex">
          <MergedWords
            words={"connected through Music"}
            type={"solid"}
          />
        </div>
      }
    </>
  );
};

RBXHeader.propTypes = {
  showSearch: PropTypes.bool,
};

export default RBXHeader;
