import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { videoFilePropTypes } from "../paragraph-video";
import { restHostBackend } from "@js/config";
import PlayIcon from "../../../../../../assets/ui/play_video.svg";
import useMediaGalleryContext from "@paragraphs/media-gallery/store/use-media-gallery-context";
import Image from "@general-components/image/image";

const VideoFile = ({ content, autoplay = false, forcePlay, loop, customThumbnail, controls, inStack }) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [showVideo, setShowVideo] = useState(!customThumbnail);
  const videoRef = useRef();
  const videoWrapperRef = useRef();

  const { swiperRef } = useMediaGalleryContext();

  const videoFile = content;

  const playPauseVideo = () => {
    setVideoPlaying((prev) => {
      const next = !prev;

      if (next) videoRef.current?.play();
      if (!next) videoRef.current?.pause();

      return next;
    });
  };

  useEffect(() => {
    if (!!showVideo && customThumbnail) {
      setVideoPlaying(true);
    }
  }, [showVideo]);

  useEffect(() => {
    // Only trigger play once user has interacted, otherwise the browser throws an error.
    // Includes "Safari Polyfill": https://stackoverflow.com/questions/76001512/reliably-polyfilling-navigator-useractivation-hasbeenactive
    if(!navigator.userActivation) {
      navigator.userActivation = {hasBeenActive:false};
      let pageActivationClickHandler = (e) => {
        if(e.isTrusted) {
          navigator.userActivation.hasBeenActive = true;
          window.removeEventListener("click", pageActivationClickHandler);
        }
      }
      window.addEventListener("click", pageActivationClickHandler);
    }

    if (navigator?.userActivation?.hasBeenActive && (forcePlay && inStack)) {
      videoRef.current?.play();
    }

    if (!forcePlay) {
      videoRef.current?.pause();
    }

  }, [forcePlay]);

  const togglePlay = () => {
    if (!showVideo) {
      setShowVideo(true);
      if (customThumbnail) {
        setVideoPlaying(true);
      }
    } else {
      playPauseVideo();
    }
  };

  // video keyboard controls
  const handleKeyDownVideo = (event) => {
    const video = videoRef.current;

    switch (event.keyCode) {
      case 32: // Space
        event.preventDefault();
        playPauseVideo();
        break;
      case 37: // Left arrow
        event.preventDefault();
        video.currentTime -= 10;
        break;
      case 39: // Right arrow
        event.preventDefault();
        video.currentTime += 10;
        break;
      case 38: // Up arrow
        event.preventDefault();
        video.volume += 0.1;
        break;
      case 40: // Down arrow
        event.preventDefault();
        video.volume -= 0.1;
        break;
      case 77: // M
        event.preventDefault();
        if (video.muted) {
          video.muted = false;
        } else {
          video.muted = true;
        }
        break;
    }
  };

  return (
    <div className="full-width local-video" data-hide-thumbnail={showVideo} ref={videoWrapperRef}>
      {videoFile?.mediaFileUrl.path && (
        <>
          {showVideo &&
            <video
              src={videoFile.mediaFileUrl.path}
              tabIndex={0}
              controls={typeof controls === "undefined" ? !autoplay && videoPlaying : controls}
              aria-controls="playpause"
              onEnded={() => {
                setVideoPlaying(false);

                // Reset swiperRef Autoplay Function if it is inside of the ContextProvider.
                swiperRef?.current?.swiper?.autoplay?.start();
                swiperRef?.current?.swiper?.slideNext();
              }}
              onClick={forcePlay ? () => { return false} : playPauseVideo}
              onKeyDown={handleKeyDownVideo}
              ref={videoRef}
              autoPlay={customThumbnail ? true : autoplay}
              loop={!!loop ? loop : false}
              muted={true}
              playsInline={true}
              preload={!!autoplay ? "auto" : "metadata"}
            />
          }

          {customThumbnail &&
            <Image
              data={customThumbnail}
            />
          }

          {!autoplay &&
            <div
              tabIndex={0}
              className="playpause"
              role="button"
              aria-label="Play or pause the video"
              onKeyDown={handleKeyDownVideo}
              onClick={() => togglePlay()}
            >
              {!videoPlaying &&
                <img src={PlayIcon} alt={"Abspielen Button"} />
              }
            </div>
          }
        </>
      )}
    </div>
  );
};

VideoFile.propTypes = {
  content: PropTypes.shape(videoFilePropTypes),
  autoplay: PropTypes.bool,
};

export default VideoFile;
