import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import useOverviewContext from "@paragraphs/overview/store/use-overview-context";
import moment from "moment-timezone";
import CustomAnimation from "@general-components/custom-animation";
import ChevronDown from "@/assets/ui/chevron-down-black.svg";
import usePageTransitionContext from "@js/page-transition/use-page-transition-context";
import ScrollSpy from "react-scrollspy-navigation";
import { chars, times } from "./scrollspy-helper";
import {festivalDayOffset} from "@js/lib/festival-day-offset";
import {waitForElm} from "@lib/wait-for-elm";
import {useDispatch, useSelector} from "react-redux";
import {preventJumpToCurrentTimeAction} from "@js/app-actions";

const Scrollspy = ({ groupedNodes, type = "chars", field = "title.0" }) => {
  const { loadMoreNodes, nodes, metaData, loading, scrollspyOpen, setScrollspyOpen, jumpToCurrentTime } = useOverviewContext();
  const { setIsLoading } = usePageTransitionContext();
  const [offset, offsetUnit] = festivalDayOffset;

  const preventJumpToCurrentTime = useSelector(
    (reduxStore) => reduxStore.appStore.preventJumpToCurrentTime
  );

  const scrollSpy = useRef();
  const valueSelector = useRef();

  const dispatch = useDispatch();
  
  const getKeyHash = (key) => {
    if (type === "times") {
      return moment(key, "DD.MM.YYYY HH:mm").format("MM-DD-HH:mm");
    } else if (type === "native") {
      return key;
    } else {
      return key[0];
    }
  };

  const getKeyLabel = (key) => {
    if (type === "times") {
      return moment(key, "DD.MM.YYYY HH:mm").format("HH:mm");
    } else if (type === "native") {
      return key;
    } else {
      return key[0];
    }
  };

  /**
   * Recursive function to load nodes until a matching element is found.
   * _nodes need to be passed through, because the recursive function has no access
   * to the updated context nodes array.
   */
  const recursiveLoading = async (e, _nodes, differentDay) => {
    setIsLoading(true);

    // Search for matching elements.
    let matchingNode;

    for (const index in _nodes) {
      const currentNode = _nodes[index];
      let nodeField = field.split(".").reduce((o, i) => o ? o[i] : null, currentNode);

      if (nodeField === null) {
        continue;
      }

      if (type === "chars") {
        nodeField = nodeField[0].toUpperCase();
      }
      if (type === "times") {
        // Check if Time is on same Real Day. If not, also use the day to compare. Can only be toggled by jumpToCurrentTime, not by user clicking.
        nodeField = moment.unix(nodeField).format(differentDay ? "DD.MM-HH:mm" : "HH:mm");
      }

      if (new Intl.Collator("de").compare(nodeField, e) >= 0) {
        matchingNode = currentNode;
        setIsLoading(false);
        break;
      }
    }

    // If there is no matching element and all nodes are loaded, scroll to the last element.
    // @todo: Because we have to use mini sitenav without proper totalRows, we need to calculate it ourselves. All nodes are loaded, if currentPage * perPage === paginationSize.
    // -1 is needed because totalrows = paginationSize + 1 to check if there are more
    const newPage = Math.ceil(nodes.length / metaData.perPage);

    if ((((newPage * metaData.perPage) + 1) !== metaData.totalRows) && !matchingNode) {
      matchingNode = _nodes[_nodes.length - 1];
      setIsLoading(false);
    }

    // If there is a matching element, scroll to it.
    if (matchingNode) {
      setIsLoading(false);

      // Wait for the nodes to be rendered, then scroll to the matching node.
      setTimeout(() => {
        const matchingElement = document.querySelector(
          `[data-entity-id="${matchingNode.id}"]`
        );
        const position =
          matchingElement.getBoundingClientRect().top + window.scrollY - 200;
        window.scrollTo({
          top: position,
          behavior: "smooth",
        });
      }, 100);
    }

    // If there are still nodes to load and no matching element was found, load more nodes.
    if (((newPage * metaData.perPage) < metaData.totalRows) && !loading && !matchingNode) {
      const newNodes = await loadMoreNodes(_nodes);

      // Call the recursive function again with the new nodes.
      recursiveLoading(e, newNodes, differentDay);
    } else if (((newPage * metaData.perPage) === metaData.totalRows) && !matchingNode) {
      setIsLoading(false);
    }
  };

  const clickHandler = (e) => {
    setScrollspyOpen(false);
    recursiveLoading(e, nodes, false);
  };

  useEffect(() => {
    // If Festival is currently happening and no search params are set, this Function jumps the current time "on Load".
    if (jumpToCurrentTime && !!nodes && !preventJumpToCurrentTime) {

      // Check if Time is on same festival day, but different real day inside of festival Offset.
      const differentDay = moment().format("DD-MM") !== moment().add(offset, offsetUnit).format("DD-MM");

      // Get current time, if time is on different day -> also use day in moment.format to check for it later.
      const currentTime = moment().startOf('hour').format(differentDay ? "DD.MM-HH:mm" : "HH:mm");

      recursiveLoading(currentTime, nodes, differentDay);

      // Prevent re-jumping to current Time when context change forces rerender of scrollspy
      dispatch(preventJumpToCurrentTimeAction(true));
    }
  }, [jumpToCurrentTime, nodes]);

  return (
    <CustomAnimation type={"appear"} isVisible={Object.entries(groupedNodes).length > 1}>
      <div
        className="scrollspy filter-control-element"
        onClick={() => setScrollspyOpen(!scrollspyOpen)}
        data-is-open={scrollspyOpen}
        ref={scrollSpy}
      >
        <ScrollSpy
          activeClass="active"
          offsetTop={200}
          // onChangeActiveId={handleChange}
        >
          {Object.entries(groupedNodes).map(([key]) => (
            <a
              href={`#${getKeyHash(key)}`}
              className="body-m"
              tabIndex={0}
              role="button"
              aria-label={`select Letter: ${getKeyLabel(key)}`}
              key={key}
            >
              {getKeyLabel(key)}
            </a>
          ))}
          <img
            className="chevron"
            src={ChevronDown}
            alt={"chevron down icon"}
          />
        </ScrollSpy>
      </div>
      <div data-is-open={scrollspyOpen} className="custom-modal scrollspy-select">
        <div className="values" ref={valueSelector}>
          {type === "chars" &&
            chars.map((char) => (
              <a
                className="body-m"
                onClick={() => clickHandler(char)}
                key={char}
              >
                {char}
              </a>
            ))}

          {type === "times" &&
            times.map((time) => (
              <a
                className="body-m"
                onClick={() => clickHandler(time)}
                key={time}
              >
                {time}
              </a>
            ))}

          {type === "native" &&
            Object.entries(groupedNodes).sort(([a], [b]) => b - a).map(([key]) => {
              return (
                <a
                  href={`#${getKeyHash(key)}`}
                  className="body-m"
                  onClick={() => setScrollspyOpen(false)}
                  key={key}
                >
                  {getKeyLabel(key)}
                </a>
              );
            })}
        </div>
      </div>
    </CustomAnimation>
  );
};

Scrollspy.propTypes = {
  /**
   * The type defines the content in the scrollspy list.
   * "chars" displays the alphabet.
   * "times" displays the times.
   * "native" displays the group keys.
   */
  type: PropTypes.oneOf(["chars", "times", "native"]).isRequired,
  /**
   * The groupedNodes object contains the nodes grouped by a specific field.
   */
  groupedNodes: PropTypes.object,
  /**
   * Path to the field that should be used as a reference
   */
  field: PropTypes.string,
};

export default Scrollspy;
