import { disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import React, {useEffect} from "react";
import PropTypes from "prop-types";

export const BodyScrollLockWithGap = ({customTargetElement}) => {
  const scrollOptions = {
    reserveScrollBarGap: true,
    allowTouchMove: el => {
      while (el && el !== document.body) {
        if (el.classList.contains('body-scroll-lock-ignore')) {
          return true;
        }

        el = el.parentElement;
      }
    }
  },
  targetElement = customTargetElement || document.getElementById('site-header');

  useEffect(() => {
    disableBodyScroll(targetElement, scrollOptions);
    return () => {
      enableBodyScroll(targetElement, scrollOptions);
    }
  }, [])

  return null;
};

BodyScrollLockWithGap.propTypes = {
  customTargetElement: PropTypes.object,
};

export default BodyScrollLockWithGap;