import React from "react";

import brandPageQuery from "./brand.graphql";
import useNodeQuery from "@hooks/use-node-query";
import NodeWrapper from "@nodes/shared-components/node-wrapper";
import {useLocation} from "react-router-dom";
import Link from "@general-components/link/link";
import { Link as ReactRouterLink } from "react-router-dom";
import BrandLogo from "@nodes/brand/brand-logo";
import {FormattedMessage} from "react-intl";
import CloseIcon from "../../../assets/rbx-company/close.svg";
import Image from "@general-components/image/image";
import MergedWords from "@js/microsites/rbx-company/general-components/merged-words";
import CustomAnimation from "@general-components/custom-animation";
import BodyScrollLockWithGap from "@lib/body-scroll-lock-with-gap";
import classNames from "classnames";

const Brand = () => {

  const location = useLocation();

  // Load Node, but force different location for nested Node which is loaded as overlay.
  const { entity, loading, error } = useNodeQuery(brandPageQuery, `/${location.hash.replace("#", "")}`);

  return (
    <NodeWrapper entity={entity} loading={loading} error={error}>
      <BodyScrollLockWithGap />
      <div className="fixed-wrapper">
        <CustomAnimation
          type={"appear"}
          isVisible={true}
        >
          <div className="blur-wrapper" />
          <div className="content-wrapper body-scroll-lock-ignore">
            <div className="container">
              <div className="row">
                <div className="col-14 col-lg-3 offset-lg-2 logo">
                  <BrandLogo item={entity}/>
                </div>

                <div className="col-16 col-lg-9 content">
                  <div className={"text-wrapper"}>
                    <h1 className="headline-s">
                      {entity?.label}
                    </h1>

                    {entity?.fieldText &&
                      <div
                        className="text body-m"
                        dangerouslySetInnerHTML={{ __html: entity.fieldText }}
                      />
                    }
                  </div>

                  {entity?.fieldLinks &&
                    <div className="links">
                      {entity.fieldLinks.map((link, index) =>
                        <React.Fragment key={index}>
                          <Link link={link}>
                            {link.title &&
                              <MergedWords
                                words={link.title}
                                type={"solid"}
                                withArrow={true}
                              />
                            }
                          </Link>
                        </React.Fragment>
                      )}
                    </div>
                  }

                  {entity?.fieldLogosPartners?.length >= 1 &&
                    <div className="sponsor-wrapper">
                      <div className="all-sponsors">
                        {entity.fieldHeadlineLogosPartners &&
                          <div className="label body-m">
                            {entity.fieldHeadlineLogosPartners}
                          </div>
                        }
                        <div className="container-fluid">
                          <div className="row">
                            {entity.fieldLogosPartners.map((sponsor, index) =>
                              <div className="col-8 col-md-4 sponsor main" key={index}>
                                <div className={`sponsor-image`}>
                                  {sponsor.fieldMediaImage &&
                                    <>
                                      <span className="sr-only">{sponsor?.fieldLink?.title}</span>
                                      <img
                                        src={sponsor.fieldMediaImage.entity.uriRawField.first.url}
                                        alt={`Logo: ${sponsor?.fieldLink?.title}`}
                                        className="logo"
                                      />
                                    </>
                                  }
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>

                <ReactRouterLink to={location.pathname} className="close-overlay">
                  <span className="sr-only">
                    <FormattedMessage id="back" />
                  </span>
                  <img src={CloseIcon} alt={"back Icon"}/>
                </ReactRouterLink>

                {entity?.fieldDetailPageImages &&
                  <div className="images-wrapper col-16 col-lg-9 offset-lg-5">
                    <div className="container-fluid">
                      {entity.fieldDetailPageImages.map((imageBlock, index) => {
                        const imageBlockClassNames = classNames({
                          "col-16": imageBlock.fieldDetailPageImages.length === 1,
                          "col-8": imageBlock.fieldDetailPageImages.length === 2,
                        });

                        return (
                          <div className="row" key={index}>
                            {imageBlock.fieldDetailPageImages.map((image, imageIndex) =>
                              <div className={imageBlockClassNames} key={imageIndex}>
                                <Image data={image.fieldMediaImage} />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </CustomAnimation>
      </div>
    </NodeWrapper>
  );
};

Brand.propTypes = {};

export default Brand;
